import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import sl from 'vuetify/es5/locale/sl'
import en from 'vuetify/es5/locale/en'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },

    lang: {
        //locales: { sl },
        //current: 'sl',

        locales: { en },
        current: 'en',
    },

    theme: {
        themes: {
            light: {
                primary: "#005A9C",
                secondary: "#005A9C",
                tertiary: "#005A9C",
                accent: '#8c9eff',
                error: '#b71c1c',
                background: '#fafafa'
                //background: '#f7f7f7'
            },
        },
    },
});
